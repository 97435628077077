import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, updateUser, getUserList } from '../../../redux/actions/userActions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faEdit, faInfoCircle, faKey, faUser, faFileInvoice, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'

import './UserTable.css'
import { baseURL } from '../../../redux/helpers';
import { getInvoices } from '../../../redux/actions/invoiceActions';
import MembersModal from './MembersModal';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import profilePlaceholder from '../../../Assets/placeholder.png';

//Import Components


function UserTable({ openInvoiceModal, openUserModal }) {
    const dispatch = useDispatch()
    const { users } = useSelector(state => state.userReducer)
    const { invoices } = useSelector(state => state.invoiceReducer)
    const token = useSelector(state => state.authReducer.token)
    const [windowHeight, setWindowHeight] = useState(`${window.innerHeight - 155}px`)
    const [usersWithPhoto, setUsersWithPhoto] = useState([])
    // const isMobile = window.innerWidth < 426 ? true : false
    const [userDetails, setUserDetails] = useState()
    const [deleteModalState, setDeleteModalState] = useState(false)
    const [emailResetPassword, setEmailResetPassword] = useState()

    //Used to adjust view based on screen size
    useEffect(() => {
        function handleResize() {
            setWindowHeight(`${window.innerHeight - 155}px`)
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const onDelete = (id) => {
        var confirm = window.confirm('Permanently delete this Member?')
        if (confirm) dispatch(deleteUser(id))
    }

    const resetPassword = async () => {
        // const confirm = window.confirm('Reset Password?')
        // if (!confirm) return null
        if (emailResetPassword) {
            const user = {
                email: emailResetPassword
            }
            try {
                const response = await axios.post('api/auth/resetPasswordRequest', user)
                window.alert(response.data.message)
            } catch (err) {
                console.log(err)
            }
        }
    }

    // const toggle = tab => {
    //     if (activeTab !== tab) setActiveTab(tab);
    // }

    useEffect(async () => {
        if (users?.length) {
            setUsersWithPhoto(users)

            // Headers
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            //if there is a token then add it to the header
            if (token) {
                config.headers['x-auth-token'] = token;
            }
            const usersWithPhoto = []
            for (var i = 0; i < users.length; i++) {
                if (users[i].photo) {
                    const thePhotoType = users[i].photo.substring(0, 4);
                    if (thePhotoType == 'http' || thePhotoType == 'data') {
                        const originalPhoto = users[i].photo;
                        usersWithPhoto.push({ ...users[i], photoSRC: originalPhoto });
                    } else {
                        const { data } = await axios.post(`${baseURL}/users/getPhoto`, { photo: users[i].photo, _id: users[i]._id }, config)
                        let buf = Buffer.from(data.Body);
                        const fileType = '.blob'
                        let base64 = buf.toString('base64');
                        const photoSRC = `data:image/${fileType};base64,${base64}`
                        usersWithPhoto.push({ ...users[i], photoSRC })
                    }
                } else {
                    const photoSRC = profilePlaceholder
                    usersWithPhoto.push({ ...users[i], photoSRC })
                }
            }
            setUsersWithPhoto(usersWithPhoto)
        }
    }, [users])

    function permissionLevel(user) {
        return <div style={{ textTransform: 'capitalize' }}>{user.permissionLevel}</div>
    }

    const [MembersModalVisible, setMembersModalVisible] = useState(false);

    function openMemberModal(user) {
        if (window.innerWidth <= 550) {
            setMembersModalVisible(!MembersModalVisible)
            setUserDetails(user)
        }
    }

    function renderActionBtn(user) {
        return (
            <Fragment>
                <span className="label visible-mobile">{permissionLevel(user)}</span>
                <span className={`label membershipStatus ${user.status}`}>{user.status}</span>
                <span className="label expiry-date hidden-mobile">
                    {user.membershipDetails ? user.membershipDetails.expiryDate : null}
                </span>
                <span className="label" style={{marginBottom:'10px'}}>{user.member_id}</span>
                <span className="label expiry-date visible-mobile">
                    {user.membershipDetails ? user.membershipDetails.expiryDate : null}
                </span>
                <div className="web-action-button">
                    {/* on web view this only shows when hover  */}
                    <React.Fragment>
                        <span className="action-btn" onClick={() => openInvoiceModal(user)}>
                            <FontAwesomeIcon icon={faFileInvoiceDollar} color="#3C3C3C" /> Invoice
                        </span>
                        <span className="buttons-divider" />
                        <span className="action-btn" onClick={() => window.open(`/profile/${user._id}`, '_top')}>
                            <FontAwesomeIcon icon={faUser} color="#3C3C3C" /> View Profile
                        </span>
                        <span className="action-btn" onClick={() => openUserModal(user)}>
                            <FontAwesomeIcon icon={faEdit} color="#3C3C3C" /> Edit Profile
                        </span>
                        <span className="action-btn" onClick={() => {
                            setEmailResetPassword(user.email)
                            setDeleteModalState(!deleteModalState)
                        }}>
                            <FontAwesomeIcon icon={faKey} color="#3C3C3C" /> Reset Password
                        </span>
                        <span className="buttons-divider" />
                        <span className="action-btn text-red" onClick={() => onDelete(user._id)}>
                            <FontAwesomeIcon icon={faTrash} /> Delete Account
                        </span>
                    </React.Fragment>
                </div>
            </Fragment>
        )
    }

    return (
        <Fragment>
            <div id="userList">
                {usersWithPhoto.map(user => {
                    var personalDetailsFlag = user.personalDetails ? true : false
                    var companyDetailsFlag = user.companyDetails ? true : false
                    user.membershipDetails && user.membershipDetails.expiryDate && console.log()
                    return (
                        <div className="row-wrapper" key={user.id} onClick={() => openMemberModal(user)}>
                            <div className="row">
                                <div className="col hidden-mobile" style={{ maxWidth: 100 }}>{permissionLevel(user)}</div>
                                <div className="col" style={{ maxWidth: 250 }}>
                                    <div class="media">
                                        <img className="profile-avatar mr-3" src={user.photoSRC ? user.photoSRC : profilePlaceholder} />
                                        <div class="media-body">
                                            <div style={{ fontWeight: 'bold' }} >{personalDetailsFlag && user.personalDetails.name}</div>
                                            <div>{companyDetailsFlag && user.companyDetails.jobTitle}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col" style={{ maxWidth: 250 }}>
                                    <div style={{ fontWeight: 'bold' }}> {companyDetailsFlag && user.companyDetails.name} </div>
                                    <div>{companyDetailsFlag && user.companyDetails.industry}</div>
                                </div>
                                <div className="col action-web">
                                    {renderActionBtn(user)}
                                </div>
                            </div>
                            <div className="row action-tablet">
                                {renderActionBtn(user)}
                            </div>
                        </div>
                    )
                })}
            </div>
            {MembersModalVisible ? <MembersModal setMembersModalVisible={setMembersModalVisible} MembersModalVisible={MembersModalVisible} userDetails={userDetails} /> : null}
            <Modal isOpen={deleteModalState} toggle={() => setDeleteModalState(!deleteModalState)}>
                <ModalBody className="text-center">
                    <p style={{ fontSize: 18 }}>Confirm reset password?</p>
                    <Button color="primary" style={{ paddingLeft: 25, paddingRight: 25 }} onClick={()=>{
                        resetPassword();
                        setDeleteModalState(!deleteModalState);
                    }}>Yes</Button>{' '}
                    <Button color="danger" style={{ paddingLeft: 25, paddingRight: 25 }} onClick={() => setDeleteModalState(!deleteModalState)}>No</Button>
                </ModalBody>
            </Modal>
        </Fragment >

    );
}

export default UserTable;