import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { getPosts, addPost, deletePost, updatePost } from '../../redux/actions/postActions'
import { getUserList } from '../../redux/actions/userActions';
import { allCategories } from './postsCategories'
import EditPostsModal from './editPostsModal';
import PostCard from './PostCard'
import "./posts.css";

const initialPost = {
    title: "",
    content: "",
    category: "",
    externalLink: "",
    userId: "",
};

function PostsList(props) {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.authReducer);
    const { users } = useSelector(state => state.userReducer);
    const { posts } = useSelector(state => state.postReducer);
    const specificPostId = props?.match?.params?.id;

    const [postsView, setPostsView] = useState('all');
    const [postsFilter, setPostsFilter] = useState('all');
    const [postsGeneralState, setPostsGeneralState] = useState('view');
    const [editPostModalOpen, setEditPostModalOpen] = useState(false);
    const [currentPost, setCurrentPost] = useState(initialPost);
    const isAdmin = user && user.user?.permissionLevel == 'Admin';

    useEffect(() => {
        dispatch(getUserList())
        dispatch(getPosts())
    }, []);

    const onDelete = (id) => {
        var confirm = window.confirm('Permanently delete this Post?');
        if (confirm) dispatch(deletePost(id))
    }

    const onSubmit = e => {
        e.preventDefault();
        if (currentPost.userId) {
            dispatch(updatePost(currentPost));
        } else {
            if (currentPost && currentPost.title && user) {
                currentPost.userId = user.user?.id;
                dispatch(addPost(currentPost));
            }
        }
        setCurrentPost(initialPost);
        setEditPostModalOpen(false);
    };

    function sortEvents(a, b) {
        const dateA = a.createdAt;
        const dateB = b.createdAt;
        if (dateA < dateB) {
            return 1;
        }
        if (dateA > dateB) {
            return -1;
        }
        return 0;
    };

    function filterEvents(ev) {
        let passed = true;
        if (postsView !== 'all') {
            if (postsView == 'mine') {
                if (ev.userId !== user.user?.id) {
                    passed = false
                }
            } else {
                if (ev.userId == user.user?.id) {
                    passed = false
                }
            }
        }
        if (postsFilter !== 'all') {
            if (postsFilter !== ev.category) {
                passed = false
            }
        }
        return passed;
    };

    const managedPosts = posts.sort((a, b) => sortEvents(a, b)).filter(ev => filterEvents(ev));
    const managedPostsCount = managedPosts.length;

    if (specificPostId) {
        const p = posts.find(p => p._id == specificPostId);
        if (p) return <><PostCard
            post={p}
            user={user}
            users={users}
            isAdmin={isAdmin}
            postsGeneralState={postsGeneralState}
            onDelete={onDelete}
            setIsOpen={setEditPostModalOpen}
            setPost={setCurrentPost}
            specificPost={true}
        />
            <EditPostsModal
                isOpen={editPostModalOpen}
                setIsOpen={setEditPostModalOpen}
                post={currentPost}
                setPost={setCurrentPost}
                onSubmit={onSubmit}
                isAdmin={isAdmin}
            />
        </>
    }

    return (
        <Fragment>
            <div className='mainPostsDiv'>
                <h2 className='postsTitleTip'>
                Welcome to the BoC Message board - Please use in accordance to common respectful practice, with business and networking focus in mind.
                </h2>
                <div className='postsControlsContainer'>
                    <span></span>
                    <div style={{ marginBottom: '15px' }}>
                        {postsGeneralState == 'view' ?
                            <Button color='primary' onClick={() => {
                                setPostsGeneralState('edit');
                                setPostsFilter('all');
                                if (!isAdmin) { setPostsView('mine') }
                            }}>
                                Manage Posts
                            </Button>
                            :
                            <Button color='primary' onClick={() => {
                                setCurrentPost(initialPost);
                                setEditPostModalOpen(true);
                            }}>
                                Add New Post
                            </Button>
                        }
                    </div>
                </div>
                <div className='postsControlsContainer'>
                    {isAdmin || postsGeneralState == 'view' ? <label>View by:
                        <select className='postsFilter' name='postsView' id='postsView' value={postsView} onChange={(e) => setPostsView(e.target.value)}>
                            <option value={'all'}>All Post</option>
                            <option value={'mine'}>My Own Post</option>
                            <option value={'other'}>Others Post</option>
                        </select>
                    </label> : null}
                    <label>Filter by:
                        <select className='postsFilter' name='postsFilter' id='postsFilter' value={postsFilter} onChange={(e) => setPostsFilter(e.target.value)}>
                            <option value={'all'}>All categories</option>
                            {allCategories.map(cat => {
                                return <option value={cat}>{cat}</option>
                            })}
                        </select>
                    </label>
                </div>
                <div className='postCardsContainer'>
                    {managedPosts.map(p => {
                        return <PostCard
                            post={p}
                            user={user}
                            users={users}
                            isAdmin={isAdmin}
                            postsGeneralState={postsGeneralState}
                            onDelete={onDelete}
                            setIsOpen={setEditPostModalOpen}
                            setPost={setCurrentPost}
                        />
                    })}
                    {managedPostsCount == 0 && <div style={{ marginTop: '40px', color: 'red' }}>
                        No matching posts
                    </div>}
                </div>
            </div>
            <EditPostsModal
                isOpen={editPostModalOpen}
                setIsOpen={setEditPostModalOpen}
                post={currentPost}
                setPost={setCurrentPost}
                onSubmit={onSubmit}
                isAdmin={isAdmin}
            />
        </Fragment>
    );
}

export default PostsList;
