import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Card, CardBody, CardHeader, ListGroup, ListGroupItem, Row, Col, Button, Modal } from 'reactstrap';
import { getEvent, updateEvent } from '../../redux/actions/eventActions'
import { getUsers } from '../../redux/actions/userActions';
import { faTrashAlt, faUser, faMinusCircle } from '@fortawesome/free-solid-svg-icons';

import './FlightArranger.css'
import FlightPDF from './FlightPDF/home'
import ParticipantModal from './ParticipantModal/ParticipantModal';

//Import Components


function FlightArranger(props) {
    const dispatch = useDispatch()
    const { event } = useSelector(state => state.eventReducer)
    const { users } = useSelector(state => state.userReducer)
    const [dragValue, setDragValue] = useState([])
    const [dragValue2, setDragValue2] = useState([])
    const [guestList, setGuestList] = useState([])
    const [displayList, setDesplayList] = useState([])
    const [flights, setFlights] = useState([])
    const [flight, setFlight] = useState(null)
    const [reset, setReset] = useState(0)
    const [disabled, setDisabled] = useState(true)
    const [participantsModal, setParticipantsModal] = useState(false)

    useEffect(() => {
        dispatch(getUsers())
        dispatch(getEvent(props.match.params.id))
    }, [])

    const error = useSelector(state => state.errorReducer)
    useEffect(() => {
        if (error && error.id === 'EVENT_FAIL') {
            //remve all Error: with regex
            const clearMessage = error.message.replace(/Error: /g, '')

            // on ok, refrech the page
            if (window.confirm(clearMessage)) {
                window.location.reload()
            }
        }
    }, [error])

    useEffect(() => {
        if (event && users?.length) {
            if (event.flightStatus === 'Submitted') {
                setDisabled(true)
            } else {
                setDisabled(false)
            }

            // Get Selected Yes users
            const userIDs = event.users
                .filter(({ RSVP }) => RSVP === 'Yes')
                .map(({ _id }) => _id)
            var userList = users
                .filter(({ _id }) => userIDs.includes(_id))
                .sort((a, b) => {
                    return a.personalDetails?.name > b.personalDetails?.name ? 1 : -1
                })
            let userListWithHandicap = []
            let userListWithoutHandicap = []

            userList.forEach(e => {
                if (e.personalDetails?.handicap) {
                    userListWithHandicap.push(e)
                } else {
                    userListWithoutHandicap.push(e)
                }
            })

            // userList = [...userListWithHandicap, ...userListWithoutHandicap]
            const arr = [...userListWithHandicap, ...userListWithoutHandicap]
            setGuestList(userList)
            setDesplayList(userList)
            if (!event.flights.length) {
                var chunk_size = 4;
                var flights = userList.map(function (e, i) {
                    return i % chunk_size === 0 ? arr.slice(i, i + chunk_size) : null;
                }).filter(function (e) { return e; });

                setFlights(flights)
            } else {
                setFlights(event.flights)
            }
        }
    }, [event, users, reset])

    useEffect(() => {
        const flatFlights = flights.flat()
        setDesplayList(guestList.filter(guest => {
            if (flatFlights.length > 0) {
                const exists = flatFlights.find(e => e._id == guest._id)
                return exists ? false : true
            } return true
        }))
    }, [flights])

    const switchPosition = () => {
        if (dragValue2[0] === dragValue[0]) return
        const person1 = isNaN(dragValue[0]) ? guestList.find(guest => guest._id === dragValue[0]) : flights[dragValue[0]][dragValue[1]]
        const person2 = isNaN(dragValue2[0]) ? guestList.find(guest => guest._id === dragValue2[0]) : flights[dragValue2[0]][dragValue2[1]]
        if (flights[dragValue2[0]]?.length < 4 || isNaN(dragValue2[0]) || isNaN(dragValue[0])) {
            setFlights(flights.map((flight, flightIndex) => {
                if (flightIndex === dragValue[0]) {
                    return flight.filter((user, userIndex) => userIndex !== dragValue[1])
                }
                if (flightIndex === dragValue2[0]) {
                    return [...flight, person1]
                }
                return flight
            }))
        } else {
            setFlights(flights.map((flight, flightIndex) => {
                if (flightIndex === dragValue[0]) {
                    return flight.map((user, userIndex) => {
                        if (userIndex === dragValue[1]) {
                            return person2
                        } else {
                            return user
                        }
                    })
                }
                if (flightIndex === dragValue2[0]) {
                    return flight.map((user, userIndex) => {
                        if (userIndex === dragValue2[1]) {
                            return person1
                        } else {
                            return user
                        }
                    })
                }
                return flight
            }))
        }
    }

    const addFlight = () => {
        setFlights([...flights, []])
    }

    const deleteFlight = (i) => {
        setFlights(flights.filter((e, index) => index !== i))
    }

    const openParticipantsModal = (i) => {
        setParticipantsModal(true)
        setFlight(i)
    }

    const addParticipant = (guest) => {
        setFlights(flights.map((flightDetails, flightIndex) => {
            if (flightIndex == flight) {
                return [...flightDetails, guest]
            }
            return flightDetails
        }))
    }

    const removeParticipant = (guest, flight) => {
        setFlights(flights.map((flightDetails, flightIndex) => {
            if (flightIndex == flight) {
                return flightDetails.filter(e => e._id !== guest._id)
            }
            return flightDetails
        }))
    }

    const saveFlight = () => {
        const requestBody = {
            _id: event._id,
            flights,
            fetchTimestamp: event.fetchTimestamp
        }

        dispatch(updateEvent(requestBody))
    }

    const resetFlight = () => {
        var confirm = window.confirm('You will lose all your updates for this flight')
        if (confirm) {
            const requestBody = {
                _id: event._id,
                flights: [],
                fetchTimestamp: event.fetchTimestamp
            }
            dispatch(updateEvent(requestBody))
            setReset(reset + 1)
        }
    }

    const sendFlights = () => {
        var confirm = window.confirm('Once you have submitted there can be no more changes')
        if (confirm) {
            const requestBody = {
                _id: event._id,
                flights,
                flightStatus: 'Sent',
                fetchTimestamp: event.fetchTimestamp
            }
            dispatch(updateEvent(requestBody))
        }
    }

    const submitFlight = () => {
        var confirm = window.confirm('Once you have submitted there can be no more changes')
        if (confirm) {
            const requestBody = {
                _id: event._id,
                flights,
                flightStatus: 'Submitted',
                fetchTimestamp: event.fetchTimestamp
            }
            dispatch(updateEvent(requestBody))
            setDisabled(true)
        }
    }

    if (!event) return <div></div>

    const eventMonth = new Date(event.date).getMonth() + 1

    return (
        <Fragment>
            <ParticipantModal
                setParticipantsModal={setParticipantsModal}
                participantsModal={participantsModal}
                displayList={displayList}
                addParticipant={addParticipant}
                removeParticipant={removeParticipant}
            />

            <div className='flightWrapper'>
                <div style={{ width: '300px' }}>
                    <div className='guestListWrapper'>
                        <Card>
                            <CardHeader>Participants</CardHeader>
                            <CardBody>
                                <ListGroup className='guestList'
                                    onDragOver={e => setDragValue2(['N/A'])}
                                    onDragEnd={e => switchPosition()}
                                >
                                    {displayList.map((guest, j) =>
                                        <ListGroupItem
                                            key={`guestlist-${guest._id}`}
                                            onDragStart={e => setDragValue([guest._id, j])}
                                            onDragOver={e => setDragValue2(['N/A', j])}
                                            onDragEnd={e => switchPosition()}
                                            style={{ color: guest?.status?.toLowerCase() == "active" ? 'green' : "red" }}
                                            draggable
                                        >{guest.personalDetails?.name}</ListGroupItem>
                                    )}
                                </ListGroup>
                                <Button style={{ marginTop: '8px' }} block onClick={() => addFlight()}>Add Flight</Button>
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <div className='flightArrangerWrapper'>
                    {flights.map((flight, i) => {
                        return <Col key={'flight' + i}>
                            <Card style={{ width: '300px', display: 'inline-block', marginBottom: '2rem' }}>
                                <CardHeader>
                                    <span className='flightTitle'>
                                        Flight {i + 1}
                                        <span className='deleteFlight' onClick={() => deleteFlight(i)}><FontAwesomeIcon icon={faTrashAlt} /></span>
                                    </span>
                                </CardHeader>
                                <CardBody>
                                    <ListGroup>
                                        {flight.map((user, j) => {
                                            return <ListGroupItem
                                                onDragStart={e => setDragValue([i, j])}
                                                onDragOver={e => setDragValue2([i, j])}
                                                onDragEnd={e => switchPosition()}
                                                draggable
                                                key={`flightList-${user?._id}`}
                                            >
                                                <div
                                                    className='flightUser'
                                                >
                                                    <div>
                                                        <strong style={{ color: user?.status?.toLowerCase() == "active" ? 'green' : "red" }}>{user?.personalDetails?.name} </strong>{user?.personalDetails?.handicap && `(${user?.personalDetails?.handicap})`}<br></br>
                                                        {user?.companyDetails && user?.companyDetails.industry}
                                                    </div>
                                                    <span
                                                        style={{ cursor: 'pointer', color: 'grey' }}
                                                        onClick={() => removeParticipant(user, i)}
                                                    ><FontAwesomeIcon icon={faMinusCircle} />
                                                    </span>
                                                </div>

                                            </ListGroupItem>

                                        })}
                                        {flight.length < 4 && <ListGroupItem style={{ textAlign: 'center', cursor: 'pointer' }}
                                            onDragOver={e => setDragValue2([i, 'new'])}
                                            onDragEnd={e => switchPosition()}
                                            onClick={() => { openParticipantsModal(i) }}
                                        ><FontAwesomeIcon icon={faUser} /></ListGroupItem>}
                                    </ListGroup>
                                </CardBody>
                            </Card>
                        </Col>
                    })}
                </div>
            </div>
            <div className='saveFooter'>
                <center>
                    <Button disabled={disabled} style={{ width: '100px' }} color='danger' onClick={() => resetFlight()}>Reset</Button>{" "}
                    <Button disabled={disabled} style={{ width: '100px' }} color='info' onClick={() => saveFlight()}>Save</Button>{" "}
                    {event.flightStatus === '' && <Button disabled={disabled} style={{ width: '100px' }} color='primary' onClick={() => sendFlights()}>Confirm</Button>}
                    {event.flightStatus === 'Sent' && <Button disabled={disabled} style={{ width: '100px' }} color='primary' onClick={() => submitFlight()}>Submit</Button>}
                    {event.flightStatus !== '' && <Fragment>
                        <FlightPDF
                            flights={flights}
                            eventMonth={eventMonth}
                        />
                    </Fragment>}
                </center>
            </div>
        </Fragment>
    );
}

export default FlightArranger;