import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'

//Import Redux Store and Provider
import store from './redux/store'
import { Provider } from 'react-redux';

//React Router Dom used to create route paths for the web app
import { BrowserRouter, Route } from 'react-router-dom'

//Import Authentication
import requireAuth from './components/utils/requireAuth'
/*
requireAuth function takes 3 values, 
requireAuth(Component, Require Authentication, Hidden)
for require Auhentication true will need user to be logged in
for Hidden true will hide navigation options in Navbar
*/

//Import Main Pages
import Landing from './pages/Landing/Landing'
import UserList from './pages/UserList/UserList'
import GroupList from './pages/GroupList/GroupList'
import Profile from './pages/Profile/Profile'
import Statistics from './pages/Statistics/Statistics'
import Search from './pages/Search/Search'
import Invoices from './pages/Invoices/Invoices'
import Awards from './pages/Awards/Awards'
import EventList from './pages/EventList/EventList'
import PostsList from './pages/Posts/PostsList'
import ResetPassword from './pages/ResetPassword/ResetPassword'
import EventDetails from './pages/EventDetails/EventDetails'
import FlightArranger from './pages/FlightArranger/FlightArranger'
import PaymentUpdate from './pages/PaymentUpdate/PaymentUpdate';
import QR from './pages/QR/QR';
import QRscanner from './pages/QR/QRScanner';
import TokensMain from './pages/TokenManagement/Tokens';

function App() {
  console.log('Developed by %c ', 'font-size: 1px; padding: 8px 20px; line-height: 0; background: url("https://mindset.swiss/wp-content/uploads/2020/12/mindset_logo.png"); background-size: contain; background-repeat: no-repeat; color: transparent;');
  console.log('https://mindset.swiss/')
  return (
    <div style={{ padding: '10px' }}>
      <Provider store={store}>
        <BrowserRouter>
          <Route path='/' exact component={requireAuth({
            AuthComponent: Landing, requireAuth: false
          })} />
          <Route path='/resetpassword/:token' exact component={requireAuth({
            AuthComponent: ResetPassword, requireAuth: false
          })} />
          <Route path='/userlist' exact component={requireAuth({
            AuthComponent: UserList, requireAuth: true, admin: true
          })} />
          <Route path='/grouplist' exact component={requireAuth({
            AuthComponent: GroupList, requireAuth: true, admin: true
          })} />
          <Route path='/invoices' exact component={requireAuth({
            AuthComponent: Invoices, requireAuth: true, admin: true
          })} />
          <Route path='/awards' exact component={requireAuth({
            AuthComponent: Awards, requireAuth: true
          })} />
          <Route path='/awards/eventPoints/:id' exact component={requireAuth({
            AuthComponent: Awards, requireAuth: true, admin: true, flightArranger: true
          })} />
          <Route path='/events' exact component={requireAuth({
            AuthComponent: EventList, requireAuth: true
          })} />
          <Route path='/posts' exact component={requireAuth({
            AuthComponent: PostsList, requireAuth: true
          })} />
          <Route path='/posts/:id' exact component={requireAuth({
            AuthComponent: PostsList, requireAuth: true
          })} />
          <Route path='/event/:id' exact component={requireAuth({
            AuthComponent: EventDetails, requireAuth: true
          })} />
          <Route path='/flight/:id' exact component={requireAuth({
            AuthComponent: FlightArranger, requireAuth: true, admin: true, hidden: false, flightArranger: true
          })} />
          <Route path='/profile' exact component={requireAuth({
            AuthComponent: Profile, requireAuth: true, renewalAccess: true
          })} />
          <Route path='/profile/:id' exact component={requireAuth({
            AuthComponent: Profile, requireAuth: true
          })} />
          <Route path='/qr' exact component={requireAuth({
            AuthComponent: QR, requireAuth: true
          })} />
          <Route path='/search' exact component={requireAuth({
            AuthComponent: Search, requireAuth: true
          })} />
          <Route path='/statistics' exact component={requireAuth({
            AuthComponent: Statistics, requireAuth: true, admin: true
          })} />
          <Route path='/paymentUpdate/:id' exact component={requireAuth({
            AuthComponent: PaymentUpdate, requireAuth: true
          })} />
          <Route path='/qrscanner' exact component={requireAuth({
            AuthComponent: QRscanner, requireAuth: true
          })} />
          <Route path='/tokenManagement' exact component={requireAuth({
            AuthComponent: TokensMain, requireAuth: true
          })} />
        </BrowserRouter>
      </Provider>
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
        integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
        crossOrigin="anonymous"
      />
    </div>
  );
}

export default App;
