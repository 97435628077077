import React, { useEffect, useState } from 'react'
import logo from '../../../../../Assets/logo.png'
import jomPay from '../../../../../Assets/JomPay.png'

// import projectStyles from '../styles.module.css'
import './InvoiceTemplate.css'

const InvoiceTemplate = ({ user, invoice }) => {
	const [address, setAddress] = useState([])
	useEffect(() => {
		if (user && user.personalDetails) {
			var address = []
			if (user.personalDetails.line1) {
				address.push(user.personalDetails.line1)
			}
			if (user.personalDetails.line2) {
				address.push(user.personalDetails.line2)
			}
			if (user.personalDetails.postcode) {
				address.push(user.personalDetails.postcode)
			}
			if (user.personalDetails.city) {
				address.push(user.personalDetails.city)
			}
			if (user.personalDetails.state) {
				address.push(user.personalDetails.state)
			}
			if (user.personalDetails.country) {
				address.push(user.personalDetails.country)
			}
			setAddress(address)
		}
	}, [user])

	const calculateTax = (amount = 0, sstRate = 0.06) => {
		return amount * sstRate
	}

	const calculatePayableAmount = (amount = 0, sstRate) => {
		return amount + calculateTax(amount, sstRate)
	}

	return (
		<div className="page-container" id='invoiceTemplate'>
			<div className="page-container01">
				<div className="page-container02">
					<img
						src={logo}
						className="thqImage page-image"
					/>
				</div>
				<div className="page-container03">
					<span className="page-text">PROFORMA INVOICE</span>
				</div>
			</div>
			<div className="page-container04">
				<div className="page-container05">
					<p style={{ lineHeight: 1.1 }}>
						<strong>Biz On Course Sdn Bhd </strong> <span style={{ fontSize: 12 }}>(201701014446/1228611-H) <br />
							W505-506, West Wing, Metropolitan Square, No. 2 Jalan PJU 8/1,
							Damansara Perdana, 47820 Petaling Jaya, Selangor, Malaysia. <br />
							Tel: +603 7493 5978 &nbsp; Fax: +603 7493 5977</span>
					</p>
					<p><strong>SST Reg No: W10-2309-32000073</strong></p>
					<span className="page-text01">
						<span className="page-text02">{user && user.companyDetails && user.companyDetails.name && user.companyDetails.name}</span>
						<br />
						{address.join(", ")}
						<br />
						<br />
						Tel: {user.companyDetails && user.companyDetails.phone ? user.companyDetails.phone : 'N/A'}
						<br />
						HP: {user.contactNumber ? user.contactNumber : 'N/A'}
						<br />
						<span className="page-text03">Attn: {user.personalDetails && user.personalDetails.name ? user.personalDetails.name : 'Sir/Madam'}</span>
					</span>
				</div>
				<div className="page-container06">
					<table className='summary-table'>
						<tbody>
							<tr>
								<td style={{ border: " 1px solid black", padding: '5px', fontWeight: '700', width: '50%' }}>Date: </td>
								<td style={{ border: " 1px solid black", padding: '5px', fontWeight: '700', width: '50%' }}>{invoice.date}</td>
							</tr>
							<tr>
								<td style={{ border: " 1px solid black", padding: '5px', fontWeight: '700', width: '50%' }}>Invoice No:</td>
								<td style={{ border: " 1px solid black", padding: '5px', fontWeight: '700', width: '50%' }}>{invoice.invoiceNo}</td>
							</tr>
							<tr>
								<td style={{ border: " 1px solid black", padding: '5px', fontWeight: '700', width: '50%' }}>Customer ID:</td>
								<td style={{ border: " 1px solid black", padding: '5px', fontWeight: '700', width: '50%' }}>{user.member_id}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
			<div className="page-container07">
				<table className='summary-table'>
					<thead>
						<tr>
							<th style={{ border: " 1px solid black", padding: '5px' }}>Item</th>
							<th style={{ border: " 1px solid black", padding: '5px' }}>Description</th>
							<th style={{ border: " 1px solid black", padding: '5px', minWidth: 130, textAlign: "center" }}>Fee (RM)</th>
							<th style={{ border: " 1px solid black", padding: '5px', minWidth: 80, textAlign: "center" }}>Units</th>
							<th style={{ border: " 1px solid black", padding: '5px', textAlign: "center" }}>Total Amount (RM)</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td style={{ border: " 1px solid black", padding: '5px', verticalAlign: "top" }}>1</td>
							<td style={{ border: " 1px solid black", padding: '5px', verticalAlign: "top" }}>Biz On Course Business Leaders Networking Over Golf Annual Membership Fees. <br /> <br /> <br /></td>
							<td style={{ border: " 1px solid black", padding: '5px', textAlign: 'right', verticalAlign: "top" }}>{invoice.fee.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2
							})}</td>
							<td style={{ border: " 1px solid black", padding: '5px', textAlign: 'center', verticalAlign: "top" }}>1</td>
							<td style={{ border: " 1px solid black", padding: '5px', textAlign: 'right', verticalAlign: "top" }}>{invoice.fee.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2
							})}</td>
						</tr>
						<tr>
							<td style={{ border: " 1px solid black", padding: '5px' }}></td>
							<td style={{ border: " 1px solid black", padding: '5px' }}></td>
							<td style={{ border: " 1px solid black", padding: '5px', textAlign: 'center' }} >Gross Total:</td>
							<td style={{ border: " 1px solid black", padding: '5px' }}></td>
							<td style={{ border: " 1px solid black", padding: '5px', textAlign: 'right' }} >{`RM ${invoice.fee.toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2
							})}`}</td>
						</tr>
						<tr>
							<td style={{ border: " 1px solid black", padding: '5px' }}></td>
							<td style={{ border: " 1px solid black", padding: '5px' }}></td>
							{console.log(invoice.sstPercentage)}
							<td style={{ border: " 1px solid black", padding: '5px', textAlign: 'center' }} >Service Tax {invoice.sstPercentage ? invoice.sstPercentage * 100 : '6'}%:</td>
							<td style={{ border: " 1px solid black", padding: '5px' }}></td>
							<td style={{ border: " 1px solid black", padding: '5px', textAlign: 'right' }} >{`RM ${calculateTax(invoice.fee, invoice.sstPercentage).toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2
							})}`}</td>
						</tr>
						<tr>
							<td style={{ border: " 1px solid black", padding: '5px' }}></td>
							<td style={{ border: " 1px solid black", padding: '5px' }}></td>
							<td style={{ border: " 1px solid black", padding: '5px', textAlign: 'center' }} ><strong>Total Payable:</strong></td>
							<td style={{ border: " 1px solid black", padding: '5px' }}></td>
							<td style={{ border: " 1px solid black", padding: '5px', textAlign: 'right' }} ><strong>{`RM ${calculatePayableAmount(invoice.fee, invoice.sstPercentage).toLocaleString(undefined, {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2
							})}`}</strong></td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="page-container08">
				<span className="page-text06">
					<strong><u>Payment Details:</u></strong>
					<br />
					CIMB Bank Berhad Account No:{" "}
					<span className="page-text08">8008563899</span>
					<br />
					Payable to:{" "}
					<span className="page-text09">BIZ ON COURSE SDN. BHD.</span>
					<br />
					For any payment made on-line to the above account or JomPAY With details as shown below, kindly submit the proof of payment to the Group
					Master of the respective Group and/or Director of Biz On Course Sdn Bhd.
					<br /><br />
					<div style={{ display: 'flex' }}>
						<img src={jomPay} style={{ width: '80px', margin: '0px 10px' }}></img>
						<div style={{ border: '2px solid black', padding: '12px', width: '300px' }}>
							<div><strong>Biller Code:</strong> 543033</div>
							<div><strong>Ref-1:</strong> Your Customer ID</div>
						</div>
					</div>
					<div style={{ margin: '5px 0px 0px 15px' }}>
						<strong>JomPAY </strong> online at Internet and Mobile Banking with your<br /> Current, Saving or Credit card Account
					</div>

					{/* <br /> */}
					<u>
						<br />
						<strong>Note:</strong>
					</u>
					<br />
					Terms: Advance Payment
					<br />
					The above is bound by the terms and conditions of Biz On Course Sdn. Bhd. (BOC), Biz On
					Course Sdn Bhd Individual Membership Application Form, and the BOC Code Of Ethics and
					Rules &amp; Regulations.
					<br />
					Validity: 30 days from the date of this invoice.
				</span>
			</div>
			<div className="page-container09">
				<div className="page-container10">
					<span className="page-text11">
						I certify the above to be true and correct to the best of my knowledge.
					</span>
				</div>
				<div className="page-container11"></div>
				<div className="page-container12">
					<span className="page-text12">
						DANIEL POON
						<br />
						CEO &amp; Co-Founder
					</span>
				</div>
			</div>
			<div className="page-container13">
				<span style={{ fontSize: 12, fontFamily: "inherit" }}>
					<strong>Biz On Course Sdn. Bhd. (201701014446/1228611-H)</strong> W505-506, West Wing, Metropolitan Square, No. 2 Jalan PJU 8/1, Damansara Perdana, 47820
					Petaling Jaya, Selangor, Malaysia. &nbsp; Tel: +603 7493 5978 &nbsp; Fax: +603 7493 5977 &nbsp; Website: www.BizOnCourse.com

				</span>
			</div>

		</div >
	)
}


export default InvoiceTemplate
