import { faCalendar, faGolfBall, faLocationArrow, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Modal, ModalHeader, Row } from 'reactstrap';
import { updateSingleEvent } from '../../../redux/actions/eventActions';
import moment from 'moment';

const MobileModal = ({ toggle, modal, event, openRsvpList, setHover, hover, user }) => {
    const dispatch = useDispatch()
    const { users } = useSelector(state => state.userReducer)
    const { id, permissionLevel, status } = user || {};
    const [userRSVP, setUserRSVP] = useState('');
    const [yesList, setYesList] = useState([]);
    const [noList, setNoList] = useState([]);
    const [involvedUser, setInvolvedUser] = useState(false);
    const [signUpEnabled,setSignUpEnabled] = useState(false);

    useEffect(() => {
        if (event && event.users && users?.length > 0) {
            const isGolfEvent = event?.bocEvent && event?.eventType == "Golf Event";
            const allowedToSignup = status.toLowerCase() == 'active' || (!isGolfEvent && status.toLowerCase() == 'excempted');
            const currentDate = new Date();
            const eventDate = new Date(event?.date);
            setSignUpEnabled(allowedToSignup && event?.users.find(user => user._id == id)?.RSVP && (eventDate > currentDate))
            let yesList = []
            let noList = []
            let noResponseList = [];

            event.users.forEach(user => {
                const userValues = users.find(e => e._id === user._id)
                if (userValues?.personalDetails?.name) {
                    user = {
                        ...user,
                        name: userValues.personalDetails?.name
                    }
                }
                if (user.RSVP) {
                    switch (user.RSVP) {
                        case 'Yes':
                            yesList.push(user)
                            break
                        case 'No':
                            noList.push(user)
                            break
                        default:
                            // noResponseList.push(user);
                            noList.push(user)
                            break
                    }
                }
            });

            setNoList(noList.sort((a, b) => (a.name > b.name) ? 1 : -1))
            setYesList(yesList.sort((a, b) => (a.name > b.name) ? 1 : -1))
            // setNoResponseList(noResponseList.sort((a, b) => (a.name > b.name) ? 1 : -1))

            for (let i = 0; i < event.users.length; i++) {
                if (event.users[i]._id === id ) {
                   
                    setInvolvedUser(true);
                    if(event.users[i].RSVP && event.users[i].RSVP !==false && event.users[i].RSVP!==true){
                        setUserRSVP(event.users[i].RSVP.toLowerCase() !== 'pending' ? event.users[i].RSVP : 'No');
                    }
                    break;
                }
                else {
                    setInvolvedUser(false);
                }

            }

        }
    }, [event, users]);

    const updateRSVP = e => {
        let _user = event.users.find(usr => usr._id === id)

        if (!_user) {
            if (user) {
                _user = {
                    _id: user?._id || user?.id,
                    email: user.email,
                    name: user.personalDetails?.name,
                    status: user.status,
                    checked: false,
                    group: user.membershipDetails?.group
                }
            }
            else {
                throw new Error('User not found')
            }

        }
        _user = { ..._user, RSVP: e.target.value }
        const requestBody = {
            _id: event._id,
            user: _user,
            fetchTimestamp: event.fetchTimestamp // for validation for concurrent updates by multiple users
        }
        dispatch(updateSingleEvent(requestBody));
        toggle();
    };

    return (
        <div>
            <Modal isOpen={modal} toggle={() => toggle()} centered>
                <ModalHeader toggle={() => toggle()}></ModalHeader>
                {event && <div style={{}}>
                    <div style={{ margin: '20px 30px' }}>
                        <span>
                            <span className={classnames('event-status-var',
                                { 'black-status': event?.status === "Closed" },
                                { 'yellow-status': event?.status === "Inactive" },
                                { 'green-status': event?.status === "Active" }
                            )}>{event?.status}</span>
                            {event?.bocEvent && <span className='boc-event-tag'>BOC Event</span>}
                        </span>
                        <span className={'event-social-links'}>
                            {event?.facebookURL && <a href={event?.facebookURL} target='_blank'><img style={{ width: '35px', marginRight: '8px' }} src={facebook} ></img></a>}
                            {event?.youtubeURL && <a href={event?.youtubeURL} target='_blank'><img style={{ width: '35px' }} src={youtube} ></img></a>}
                        </span>
                        <div className='event-details-summary'>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <h3 style={{ fontSize: '26px', fontWeight: '700', }}>{event?.name}</h3>
                                <span>
                                    <FontAwesomeIcon icon={faLocationArrow} /> &nbsp;
                                    <span style={{ whiteSpace: "pre-wrap" }}>
                                        <a style={{ color: 'inherit', fontWeight: '500', fontSize: '18px', textDecoration: 'none' }} target='blank' href={event?.locationURL ?? ''}>{event?.location}</a>
                                    </span>
                                </span>
                                <span>
                                    <FontAwesomeIcon icon={faCalendar} /> &nbsp;
                                    <span style={{ whiteSpace: "pre-wrap", fontSize: '18px' }}>{event?.date}</span>
                                </span>
                            </div>
                        </div>

                        {involvedUser && signUpEnabled && moment(event?.date).isAfter(moment()) ? (
                                            <div style={{ marginTop: "14px" }}>
                                            <select
                                              value={userRSVP}
                                              type="select"
                                              onChange={(e) => updateRSVP(e)}
                                              onClick={(e) => e.stopPropagation()}
                                              disabled={event?.eventClosed}
                                              style={{
                                                padding: "10px",
                                                borderRadius: "6px",
                                                color: userRSVP === "No" ? "#D12229" : "green",
                                                fontWeight: "bold",
                                                backgroundColor: "white",
                                              }}
                                            >
                                              <option value="Yes" style={{ color: "green" }}>
                                                Going{" "}
                                              </option>
                                              <option value="No" style={{ color: "#D12229" }}>
                                                Not Going
                                              </option>
                                            </select>
                                          </div>
                                        ) : (
                                          involvedUser && signUpEnabled && (
                                            <div style={{ marginTop: "10px" }}>
                                              <span>
                                                Status: {userRSVP === "Yes" ? "Going" : "Not Going"}
                                              </span>
                                            </div>
                                          )
                            )}
                    </div>

                    <Fragment>
                        <div className='event-details-schedule' style={{ margin: 'auto', border: '1px solid lightgrey', borderRadius: '6px' }}>
                            <Row>
                                <Col>
                                    <p>
                                        <span style={{ fontWeight: "bold" }}>Schedule</span><br></br>
                                        <span style={{ whiteSpace: "pre-wrap" }}>{event?.description}</span>
                                    </p>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <p>
                                        <span style={{ fontWeight: "bold" }}>Additional Details</span><br></br>
                                        <span style={{ whiteSpace: "pre-wrap" }}>{event?.additionalDetails}</span>
                                    </p>
                                </Col>
                            </Row>
                        </div>

                        <div className='event-details-schedule' style={{ margin: 'auto', marginTop: '30px', padding: '20px', border: '1px solid lightgrey', borderRadius: '6px' }}>
                            <div>Participants</div>
                            <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '10px' }}>
                                <div

                                    onClick={() => openRsvpList(yesList)}
                                    onMouseOver={() => setHover(1)}
                                    onMouseLeave={() => setHover(null)}
                                    style={{ backgroundColor: hover === 1 ? '#EEEEEE' : 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '48%', borderRadius: '6px', border: '1px solid lightgrey' }}
                                >
                                    <span className='column-rsvp-count'>{yesList.length}</span>
                                    <span className='column-rsvp-label' style={{ color: 'green' }}>GOING </span>
                                </div>
                                <div
                                    className='column-rsvp'
                                    onClick={() => openRsvpList(noList)}
                                    onMouseOver={() => setHover(2)}
                                    onMouseLeave={() => setHover(null)}
                                    style={{ backgroundColor: hover === 1 ? '#EEEEEE' : 'white', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '48%', borderRadius: '6px', border: '1px solid lightgrey', backgroundColor: 'white' }}
                                >
                                    <span className='column-rsvp-count'>{noList.length}</span>
                                    <span className='column-rsvp-label' style={{ color: 'red' }}>NOT GOING </span>
                                </div>

                            </div>
                        </div>

                        {event?.permissionLevel === "Flight Arranger" && (<>
                            {pastEvent && event.bocEvent && event.eventType == 'Golf Event' ? <div
                                className="btn admin-button"
                                onClick={() => window.open(`/awards/eventPoints/${event._id}`)}
                                style={{ margin: 'auto' }}
                            >
                                <FontAwesomeIcon icon={faTrophy} /> Edit Points
                            </div> : <div className='event-card-flight-arrangger'>
                                <div
                                    className="btn"
                                    onClick={() => window.open(`/flight/${event._id}`)}
                                    style={{ margin: 'auto' }}
                                ><FontAwesomeIcon icon={faGolfBall} /> Flight Arranger</div>
                            </div>}
                        </>)
                        }
                        <br />
                        <br />
                    </Fragment>
                </div>}

            </Modal>
        </div>
    );
}

export default MobileModal;