import React, { Fragment, useState, useEffect } from 'react';
import { Button, Input, Label, View, Col, FormGroup, Form, Modal } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import countryList from '../../Profile/countryList.json'

//Import Components

import './ProfilePhoto.css'
import Compress from '../../ImageCompression/imageCompression.js';
import UpdatePhotoModal from './UpdatePhotoModal/UpdatePhotoModal'

function ProfilePhoto({ uploadFile, userDetails, setImageSrc, imageSrc, isVisitor, isAdmin, type, fields, onChange, tab = null }) {
    const dispatch = useDispatch()
    const [tempFile, setTempFile] = useState(null);
    const [profileDisplay, setProfileDisplay] = useState(null)
    const [uploadButton, setUploadButton] = useState(false)
    const [flag, setFlag] = useState();
    const { personalDetails, _id } = userDetails
    const uploadAnswer = async (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append('name', personalDetails.name);
        data.append('_id', _id);
        data.append('image', tempFile);
        dispatch(uploadFile(data))
        setUploadButton(false)
        setImageSrc(profileDisplay)
    };

    const pictureChange = (e) => {
        const file = e.target.files[0];
        // have to check the photo type for this
        if (file) {
            setTempFile(file);
            setImageSrc(URL.createObjectURL(file))
            setUploadButton(true)

        }
    };

    useEffect(() => {

        var userNationality = userDetails && userDetails.personalDetails && userDetails.personalDetails.nationality ? userDetails.personalDetails.nationality : null
        var nationality = countryList.find(country => country.nationality === userNationality)
        if (nationality) {
            setFlag(`https://purecatamphetamine.github.io/country-flag-icons/3x2/${nationality.alpha_2_code}.svg`)
        }
    }, [userDetails])


    return (
        <Fragment>

            <div className={`profile-photo-wrapper ${type}`}>
                <div className='imageCategory'>
                    <UpdatePhotoModal
                        imageSrc={imageSrc}
                        id={type}
                        disabled={isVisitor ? !isAdmin : false}
                        setImageSrc={setImageSrc}
                        setTempFile={setTempFile}
                        setUploadButton={setUploadButton}
                        isVisitor={isVisitor}
                        isAdmin={isAdmin}
                        uploadAnswer={uploadAnswer}
                        uploadButton={uploadButton}
                        profileDisplay={profileDisplay}
                        setProfileDisplay={setProfileDisplay}
                    />
                    <div className="imageDescription">
                        {fields ? fields.map(({ title, type, name, id = null, required = false, options = [], disabled = false, min, max, step, pattern, hidden }) => {
                            if (hidden && isVisitor && !isAdmin) { return null }
                            var proceed = false
                            if (tab === 'personal') {
                                if (name === 'displayName') { proceed = true }
                            } else {
                                if (name === 'name' || name === 'industry') { proceed = true }
                            }
                            if (proceed) {
                                return <FormGroup style={{ height: 'fit-content' }}>
                                    <Label>{title}</Label>
                                    <Input
                                        type={type}
                                        value={id && userDetails[id] ? userDetails[id][name] : userDetails[name]}
                                        min={min}
                                        max={max}
                                        name={name}
                                        pattern={pattern}
                                        step={step}
                                        id={id}
                                        required={required}
                                        onChange={onChange}
                                        disabled={isVisitor ? !isAdmin : disabled}
                                    >
                                        {options.map((e, i) => <option key={e.value + i} value={e.value}>{e.name}</option>)}
                                    </Input>
                                </FormGroup>
                            } else {
                                return null
                            }
                        }) : null}
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default ProfilePhoto;