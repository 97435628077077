import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { Table } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import '../Profile.css'

import { getInvoicesById } from '../../../redux/actions/invoiceActions'

//Import Components
import InvoiceView from '../../UserList/InvoiceModal/InvoiceView/InvoiceView'

function InvoiceListNew() {
    const dispatch = useDispatch()
    const { invoices } = useSelector(state => state.invoiceReducer)
    const { user } = useSelector(state => state.userReducer)

    useEffect(() => {
        dispatch(getInvoicesById())
    }, [])

    const [viewState, setViewState] = useState(false)
    const [invoice, setInvoice] = useState(null)

    const viewInvoice = invoice => {
        setInvoice(invoice)
        toggleViewState()
    }

    const toggleViewState = () => {
        setViewState(!viewState)
    }
    return (
        <Fragment>
            <InvoiceView
                viewState={viewState}
                toggleViewState={toggleViewState}
                user={user}
                invoice={invoice}
            />
            <div className='table-responsive'>
                <table className='table' style={{ whiteSpace: "nowrap" }}>
                    <tbody>
                        <tr>
                            <th>Invoice No.</th>
                            <th> Amount </th>
                            <th> Date  </th>
                            <th>   </th>
                        </tr>

                        {invoices.map(invoice =>

                            <tr>

                                <td >{invoice.invoiceNo}</td>
                                <td > MYR {invoice.fee}</td>
                                <td >{invoice.date}</td>

                                <td>
                                    <span
                                        onClick={() => { viewInvoice(invoice) }}
                                    >
                                        <FontAwesomeIcon icon={faSearch} />
                                    </span>
                                </td>
                            </tr>)}
                    </tbody>
                </table>

            </div>

        </Fragment>
    );
}

export default InvoiceListNew;