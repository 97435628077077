import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Input, Card, Row, Col, Container, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faSearch, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { updateInvoice } from '../../../../redux/actions/invoiceActions'
import './InvoiceTable.css';


const InvoiceTable = ({ viewInvoice, allDetails, externalDownloadPDF }) => {
    const dispatch = useDispatch()
    const { invoices } = useSelector(state => state.invoiceReducer)
    const { statuspaid } = useSelector(state => state.invoiceReducer)
    const { users } = useSelector(state => state.userReducer)
    const [userList, setUserList] = useState(null)

    useEffect(() => {
        if (allDetails && invoices && users?.length > 0) {
            var object = {}
            users?.forEach(e => {
                object[e._id] = {
                    name: e.personalDetails?.name,
                    company: e.companyDetails ? e.companyDetails?.name : 'N/A'
                }
            });
            setUserList(object)
        }
    }, [invoices, users])

    //create another useEffect to trigger use  modal box view
    useEffect(() => {
        //check is the statuspaid -- true
        if (statuspaid === true) {
            dispatch(updateInvoice)

        }
    }, [statuspaid])

    function previewInvoice(e, invoice) {

        if (e.target.value === 'Paid') {
            viewInvoice(invoice, 'Comfirm Payment')


        }
        else {
            dispatch(updateInvoice({ _id: invoice._id, status: e.target.value }))
        }

    }

    return (
        <Fragment>
            {invoices.sort((a, b) => new Date(b.date) - new Date(a.date)).map((invoice, i) => (
                <Fragment key={i}>
                    <Row style={{ alignItems: 'center' }}>
                        <Col sm="5" xs="5">
                            {invoice.invoiceNo}
                        </Col>
                        <Col sm="7" xs="7" style={{ paddingLeft: 0 }}>
                            <div class="input-group">
                                {/* <input type="text" class="form-control" placeholder="Search" /> */}
                                <Input
                                    type='select'
                                    value={invoice.status}
                                    // onChange={e => dispatch(updateInvoice({ _id: invoice._id, status: e.target.value }))}
                                    onChange={(e) => previewInvoice(e, invoice)}
                                // disabled = { //check if invoice status is 'Paid' = true, else = false}
                                // disabled = {invoice.status === 'Paid'} 
                                >
                                    <option ></option>
                                    <option >Emailed</option>
                                    <option >Paid</option>
                                </Input>
                                <div class="input-group-append right">
                                    <button class="btn btn-secondary" type="button" onClick={() => { viewInvoice(invoice) }}>
                                        <FontAwesomeIcon icon={faSearch} /> Detail
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {/* <div>
                        {allDetails && <Fragment>
                            <div style={{ fontWeight: 'bold' }}>{userList && userList[invoice.user_id].name}</div>
                            <div>{userList && userList[invoice.user_id].company}</div>
                        </Fragment>}
                    </div> */}
                </Fragment>
            ))}
        </Fragment>
    );
}

export default InvoiceTable;