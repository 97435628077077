// Authentication/Login/Password Resetting and Registration related types
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_FAIL = 'RESET_FAIL';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const AUTH_LOADING = 'AUTH_LOADING'
export const CREATE_PASSWORD = 'CREATE_PASSWORD'

// error reducers
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';

// For Users
export const GET_USERS = 'GET_USERS'
export const USER_FAIL = 'USER_FAIL'
export const ADD_USER = 'ADD_USER'
export const DELETE_USER = 'DELETE_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const SET_USER_UPDATED = 'SET_USER_UPDATED'
export const GET_USER = 'GET_USER'
export const DOWNLOAD_USER_LIST = 'DOWNLOAD_USER_LIST'
export const SET_USER = 'SET_USER'
export const SET_PHOTO = 'SET_PHOTO'
export const SET_COMPANY_PHOTO = 'SET_COMPANY_PHOTO'
export const UPDATE_USER_MOBILE = 'UPDATE_USER_MOBILE'

// For Groups
export const GET_GROUPS = 'GET_GROUPS'
export const GROUP_FAIL = 'GROUP_FAIL'
export const ADD_GROUP = 'ADD_GROUP'
export const DELETE_GROUP = 'DELETE_GROUP'
export const UPDATE_GROUP = 'UPDATE_GROUP'
export const SET_GROUP_UPDATED = 'SET_GROUP_UPDATED'
export const GET_GROUP = 'GET_GROUP'
export const SET_GROUP = 'SET_GROUP'
export const SET_GROUP_PHOTO = 'SET_GROUP_PHOTO'

// For Invoices
export const GET_INVOICES = 'GET_INVOICES'
export const SET_INVOICE = 'SET_INVOICE'
export const INVOICE_FAIL = 'INVOICE_FAIL'
export const ADD_INVOICE = 'ADD_INVOICE'
export const DELETE_INVOICE = 'DELETE_INVOICE'
export const UPDATE_INVOICE = 'UPDATE_INVOICE'
export const SET_INVOICE_UPDATED = 'SET_INVOICE_UPDATED'
export const GET_INVOICE = 'GET_INVOICE'
export const EMAIL_INVOICE = 'EMAIL_INVOICE'
export const NORENEWAL_EMAIL = 'NORENEWAL_EMAIL'


// For Scores
export const GET_SCORES = 'GET_SCORES'
export const SET_SCORE = 'SET_SCORE'
export const SCORE_FAIL = 'SCORE_FAIL'
export const ADD_SCORE = 'ADD_SCORE'
export const DELETE_SCORE = 'DELETE_SCORE'
export const UPDATE_SCORE = 'UPDATE_SCORE'
export const SET_SCORE_UPDATED = 'SET_SCORE_UPDATED'
export const GET_SCORE = 'GET_SCORE'
export const GET_HOLE_IN_ONE_SCORES = 'GET_HOLE_IN_ONE_SCORES'
export const SCORES_LOADING = 'SCORES_LOADING'


// For Events
export const GET_EVENTS = 'GET_EVENTS'
export const GET_EVENTS_HISTORY = 'GET_EVENTS_HISTORY'
export const EVENT_FAIL = 'EVENT_FAIL'
export const ADD_EVENT = 'ADD_EVENT'
export const DELETE_EVENT = 'DELETE_EVENT'
export const UPDATE_EVENT = 'UPDATE_EVENT'
export const SET_EVENT_UPDATED = 'SET_EVENT_UPDATED'
export const GET_EVENT = 'GET_EVENT'
export const SET_EVENT = 'SET_EVENT'
export const SEND_EMAIL = 'SEND_EMAIL'
export const UPDATE_RSVP = 'UPDATE_RSVP'
export const UPDATE_PAYMENT = 'UPDATE_PAYMENT'
export const UPDATE_EVENT_MEMBERS = 'UPDATE_EVENT_MEMBERS'
export const UPDATE_EVENT_MEMBERS_LOADING = 'UPDATE_EVENT_MEMBERS_LOADING'
export const SET_EVENT_MEMBERS_UPDATED = 'SET_EVENT_MEMBERS_UPDATED'


// For Posts
export const GET_POSTS = 'GET_POSTS'
export const POST_FAIL = 'POST_FAIL'
export const ADD_POST = 'ADD_POST'
export const DELETE_POST = 'DELETE_POST'
export const UPDATE_POST = 'UPDATE_POST'
export const SET_POST_UPDATED = 'SET_POST_UPDATED'
export const GET_POST = 'GET_POST'
export const SET_POST = 'SET_POST'


// Access Token Tab for member
export const GET_TOKEN_TAB = 'GET_TOKEN_TAB'
export const UPDATE_TOKEN_TAB = 'UPDATE_TOKEN_TAB'