import React, { useState, Fragment } from 'react';
import { useSelector } from 'react-redux'
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
} from 'reactstrap';

import LoginModal from './auth/LoginModal'
import RegisterModal from './auth/RegisterModal'
import Logout from './auth/Logout'
import './Navbar.css'
import logo from '../../Assets/logo.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faTimes
} from "@fortawesome/free-solid-svg-icons";

const NavBar = (props) => {
  const { imageSrc,showMenu,setShowMenu } = props;
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const auth = useSelector((state) => state.authReducer);
  const { isAuthenticated, isLoading, user } = auth;
  const isAdmin = user && user.user.permissionLevel && user.user.permissionLevel === 'Admin'
  const isFlightArranger = user && user.user.permissionLevel && user.user.permissionLevel === 'Flight Arranger'
  const isMobileSize = window.innerWidth < 808 ? true : false
  const {accessTokenTab} = useSelector((state) => state.tabReducer);

  function renderMenu() {
    return isAuthenticated // TO display different Navbars for each state
      ? <Fragment>
        <NavItem>
          <NavLink href="/profile">My Profile</NavLink>
        </NavItem>

        <NavItem>
          <NavLink href="/qr">My QR</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/search">Search Profiles</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/posts">Posts</NavLink>
        </NavItem>
        <NavItem>
          <NavLink href="/awards">Awards</NavLink>
        </NavItem>
        {!isAdmin && accessTokenTab &&  <NavItem>
          <NavLink href="/tokenManagement">Tokens</NavLink>
        </NavItem>}
       
        {isAdmin && <Fragment>
          <NavItem>
            <NavLink href="/tokenManagement">Tokens</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/userlist">Member List</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/grouplist">Group List</NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink href="/awards">Awards</NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink href="/invoices">Invoice List</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/statistics">Statistics</NavLink>
          </NavItem>
        </Fragment>}
        <NavItem>
          <NavLink href="/events">Events</NavLink>
        </NavItem>
        {/* give conditional operator for isFLightArranger */}
        <NavItem>
          <Logout  setShowMenu={setShowMenu}/>
        </NavItem>
      </Fragment>
      : !isLoading && <Fragment>
        {/* <NavItem>
          <LoginModal />
        </NavItem> */}
      </Fragment>
  }

  return (
    <Fragment>
      <div style={{ position:!showMenu? "sticky":'initial', top: "0px", zIndex: "5" }}>
        <Navbar light expand="lg">
          <Fragment>
            <NavbarBrand href="https://bizoncourse.com/">
              <img src={logo} style={{ height: "70px" }} />
            </NavbarBrand>
            {isAuthenticated &&
            <NavbarToggler
              onClick={() => setShowMenu(!showMenu)}
              style={{ borderColor: "transparent" }}
            />
            }
            <Collapse isOpen={isOpen} navbar>
              <Nav className="ml-auto" navbar>
                {renderMenu()}
              </Nav>
            </Collapse>
          </Fragment>
        </Navbar>
      </div>
      {showMenu ? (
          <Fragment>
            <div style={{ position: 'absolute', backgroundColor: '#ffffff', top: 0, bottom: 0, left: 0, right: 0, zIndex: 99, height:'min-content', paddingBottom:'24px' }}>
            <div onClick={() => setShowMenu(!showMenu)} className="text-right" style={{ padding: 25,margin:'12px 16px' }}>
              <FontAwesomeIcon className="icons" icon={faTimes} style={{ fontSize: 28 }} color="#606060" />
            </div>
            <div className="text-center">
              <a href="https://bizoncourse.com/"><img src={logo} style={{ height: '100px' }} /></a>
            </div>
            <div className="mobile-menu text-center">
              <ul className="list-unstyled">{renderMenu()}</ul>
            </div>
          </div>
          </Fragment>
        ) : null}
    </Fragment>
  );
};

export default NavBar;